/* eslint-disable prettier/prettier */
import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Box, Card, Section, Container, Columns } from 'react-bulma-components'

// Components
import Separator from 'components/separator'

function HowCanWeHelp() {
  return (
    <Section className="has-background-grey-white-ter is-medium">
      <Container className="content is-widescreen">
        <Columns className="is-vcentered is-mobile">
          <Columns.Column className="is-8-desktop is-12-tablet is-12-mobile content ">
            <h2 className="title has-text-left is-size-1-desktop is-size-2-tablet is-size-2-mobile has-text-grey-dark">
              Where you get stuck
            </h2>
            <p className="is-size-4 has-text-left">
              In today's rapidly evolving digital landscape, staying competitive isn't just about having a mobile app — it's about having the <i>right</i> mobile app. At Pocketworks, we understand the challenges you face:
            </p>
          </Columns.Column>
        </Columns>
        <Columns className="mt-6">
          <Columns.Column className="is-4-desktop is-6-tablet">
            <Link to="/services/advisory/business-case-help">
              <Card className="classy has-bg-blue is-equal-height">
                <span className="circle small is-top-right" />
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  Business Case
                </h2>
                <h3 className="has-text-weight-light is-marginless mt-2 pb-3">
                  Struggling to convince stakeholders to invest
                </h3>
                <p className="mt-4">
                  How will you tell a convincing story to stakeholders or investors? How robust is your cost estimate, timeline and revenue forecast for digial initiatives? 
                </p>
              </Card>
            </Link>
          </Columns.Column>
          <Columns.Column className="is-4-desktop is-6-tablet">
            <Link to="/services/advisory/digital-possibilities">
              <Card className="classy has-bg-purple is-equal-height">
                <span className="circle small is-bottom-left" />
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  Digital Opportunities
                </h2>
                <h3 className="has-text-weight-light is-marginless mt-2 pb-3 is-6-tablet">
                  Losing customers to more digitally savvy competitors
                </h3>
                <p className="mt-4">
                  How are you employing apps, AI and data? And how do they help
                  you meet the changing needs of your customers?
                </p>
              </Card>
            </Link>
          </Columns.Column>
          <Columns.Column className="is-4-desktop is-6-tablet">
            <Link to="/services/advisory/concept-design">
              <Card className="classy has-bg-green is-equal-height">
                <span className="circle small is-top-right" />
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  Concept Viability
                </h2>
                <h3 className="has-text-weight-light is-marginless pb-3 mt-2">
                  Making stuff people will actually use
                </h3>
                <p className="mt-4 mb-5">
                  How do you ensure customers will engage with your app?  
                  What customer insight will inform your choices? 
                  How will you drive growth?
                </p>
              </Card>
            </Link>
          </Columns.Column>
          <Columns.Column className="is-3-desktop is-6-tablet is-hidden">
            <Link to="/services/advisory/growth-diagnostic">
              <Card className="classy has-bg-orange is-equal-height">
                <span className="circle small is-bottom-left" />
                <h2 className="title is-outlined remove-outline has-text-dark is-size-6 mt-3">
                  App Growth
                </h2>
                <h3 className="has-text-weight-light is-marginless mt-2 pb-3">
                  Driving revenue from apps
                </h3>
                <p className="mt-4">
                  Imagine your customer journey is a sales funnel. Do you know
                  where customers drop off? How does this impact
                  revenue? 
                </p>
              </Card>
            </Link>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default HowCanWeHelp
