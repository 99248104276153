import PropTypes from 'prop-types'
import React from 'react'

// Layout
import Layout from 'layout'

// Page Components
import Hero from './components/hero'
import HowCanWeHelp from './components/how-can-we-help'
import Clients from './components/clients'
import Blogs from './components/blogs'
import Services from './components/services'
import ArrangeChat from './components/arrange-chat'
import About from './components/about'
// import Stats from '../services/components/stats/Stats'

function Homepage({ data }) {
  return (
    <Layout>
      <Hero data={data} featuredMedia={data.page.featuredMedia} />
      <HowCanWeHelp />
      <Clients data={data} />
      <Blogs data={data} />
      {/* <Services /> */}
      <ArrangeChat />
      <About />
    </Layout>
  )
}

Homepage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Homepage
